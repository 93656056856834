import React from 'react'
import { navigate } from 'gatsby-link'

import CSS from './circle-button.module.css'

const CircleButton = props => {
    return (
        <button 
            className={CSS.button}
            onClick={() => navigate(props.to)}
        >
            {props.children}
        </button>
    )
}

export default CircleButton