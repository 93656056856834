import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions'

import Layout from "../global/layout"
import SEO from "../global/seo"

import CircleButton from '../components/circle-button'

import CSS from "./404.module.css"

const NotFoundPage = () => (
  <PageTransition>

    <Layout>
      <SEO title="404: Not found" />

      <h2 className={CSS.big}><span className={CSS.bigSlash}>/</span>404</h2>

      <div className={CSS.container}>

        <div className={CSS.homeButton}>
            <CircleButton to="/" >home</CircleButton>
        </div>

        <h1>NOT FOUND <br/>:(</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>

      </div>

    </Layout>

  </PageTransition>
)

export default NotFoundPage
